import { defineStore } from "pinia";

export const authStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    username: '',
    jSessionid: '',
  }),
  actions: {
    async check() {
      const res = await fetch('/api/v1/lcm/auth/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'JSESSIONID': this.jSessionid
        }
      });
        
      if (res.status !== 200) return false;

      const { username } = await res.json();
      this.username = username;
      this.isAuthenticated = true;
      return true;
    },
    async signin(username, password) {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
        })
      });
      if (res.status !== 200) {
        return false;
      }
      const data = await res.json();
      console.log("RESPONSE FROM SIGNIN: "+  data);
      this.username = data.username;
      this.isAuthenticated = true;
      this.jSessionid = data.JSESSIONID;
      console.log("STATE USERNAME: "+  this.username);
      console.log("STATE AUTH: "+  this.isAuthenticated);
      console.log("STATE JSESSIONID: "+  this.jSessionid);
      return true;
    },
    async signout() {
      const res = await fetch('/api/v1/lcm/auth/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'JSESSIONID': this.jSessionid
        }
      });
      if (res.status !== 200) return false;

      this.username = '';
      this.isAuthenticated = false;
      return true;
    },
    async recover(/* code */) {
      this.username = 'Anders Johansson';
      this.isAuthenticated = true;
      localStorage.setItem('username', this.username);
    }
  }
});
