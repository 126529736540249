import { createRouter, createWebHistory } from 'vue-router';
import { authStore } from '@/store/auth';

const authBeforeEnter = () => {
  const auth = authStore();
  if (auth.isAuthenticated === false) {
    return { name: 'signin' };
  }
};
const unauthBeforeEnter = () => {
  const auth = authStore();
  if (auth.isAuthenticated === true) {
    return { name: 'dashboard' };
  }
};

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/SigninView.vue'),
    beforeEnter: unauthBeforeEnter,
  },
  {
    path: '/recovery-support',
    name: 'recovery-support',
    component: () => import(/* webpackChunkName: "recovery-support" */ '@/views/RecoverySupportView.vue'),
    beforeEnter: unauthBeforeEnter,
  },
  {
    path: '/',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/AuthView.vue'),
    beforeEnter: authBeforeEnter,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/auth-views/DashboardView.vue'),
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: 'sse',
        name: 'sse',
        component: () => import(/* webpackChunkName: "sse" */ '@/views/auth-views/SSEComponent.vue'),
        meta: {
          title: 'SSE Component'
        }
      },
      {
        path: 'topology/overview',
        name: 'topology/overview',
        component: () => import(/* webpackChunkName: "topology-details" */ '@/views/auth-views/TopologyDetailsView.vue'),
        meta: {
          title: 'Topology Overview'
        }
      },
      {
        path: 'commissioning/overview',
        name: 'commissioning/overview',
        component: () => import(/* webpackChunkName: "commissioning-overview" */ '@/views/auth-views/CommissioningOverviewView.vue'),
        meta: {
          title: 'Commissioning Overview'
        }
      },
      {
        path: 'security/overview',
        name: 'security/overview',
        component: () => import(/* webpackChunkName: "security-overview" */ '@/views/auth-views/SecurityOverviewView.vue'),
        meta: {
          title: 'Security Overview'
        }
      },
      {
        path: 'sw-releases/overview',
        name: 'sw-releases/overview',
        component: () => import(/* webpackChunkName: "sw-releases-overview" */ '@/views/auth-views/SwReleasesOverviewView.vue'),
        meta: {
          title: 'UCC SW Releases Overview'
        }
      },
      {
        path: 'product-details',
        name: 'product-details',
        component: () => import(/* webpackChunkName: "product-details" */ '@/views/auth-views/ProductDetailsView.vue'),
        meta: {
          title: 'Product Details'
        }
      },
      {
        path: 'network-performance/overview',
        name: 'network-performance/overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/auth-views/NetworkPerformanceOverviewView.vue'),
        meta: {
          title: 'Overview'
        }
      },
      {
        path: 'network-performance/alarms',
        name: 'network-performance/alarms',
        component: () => import(/* webpackChunkName: "alarms" */ '@/views/auth-views/NetworkPerformanceAlarmsView.vue'),
        meta: {
          title: 'Alarms'
        }
      },
      {
        path: 'network-performance/radio',
        name: 'network-performance/radio',
        component: () => import(/* webpackChunkName: "alarms" */ '@/views/auth-views/NetworkPerformanceRadioView.vue'),
        meta: {
          title: 'Radio'
        },
        children: [
          {
            path: '',
            name: 'status-overview',
            component: () => import(/* webpackChunkName: "status-overview" */ '@/views/auth-views/network-performance-radio-views/StatusOverviewView.vue')
          },
          {
            path: '5g-connectivity',
            name: '5g-connectivity',
            component: () => import(/* webpackChunkName: "5g-connectivity" */ '@/views/auth-views/network-performance-radio-views/5GConnectivityView.vue')
          },
          {
            path: '4g-connectivity',
            name: '4g-connectivity',
            component: () => import(/* webpackChunkName: "4g-connectivity" */ '@/views/auth-views/network-performance-radio-views/4GConnectivityView.vue')
          }
        ]
      },
      {
        path: 'network-performance/router',
        name: 'network-performance/router',
        component: () => import(/* webpackChunkName: 'router' */ '@/views/auth-views/NetworkPerformanceRouterView.vue'),
        meta: {
          title: 'Router'
        }
      },
      {
        path: 'network-performance/server',
        name: 'network-performance/server',
        component: () => import(/* webpackChunkName: 'server' */ '@/views/auth-views/NetworkPerformanceServerView.vue'),
        meta: {
          title: 'Server'
        }
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import(/* webpackChunkName: 'subscription' */ '@/views/auth-views/SubscriptionView.vue'),
        meta: {
          title: 'Subscription'
        }
      },
      {
        path: 'user-equipment',
        name: 'user-equipment',
        component: () => import(/* webpackChunkName: 'user-equipment' */ '@/views/auth-views/UserEquipmentView.vue'),
        meta: {
          title: 'User Equipment'
        }
      },
      {
        path: 'product-details',
        name: 'product-details',
        component: () => import(/* webpackChunkName: "product-details" */ '@/views/auth-views/ProductDetailsView.vue'),
        meta: {
          title: 'Product Details'
        }
      },
      {
        path: 'network-performance/overview',
        name: 'network-performance/overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/auth-views/NetworkPerformanceOverviewView.vue'),
        meta: {
          title: 'Overview'
        }
      },
      {
        path: 'network-performance/alarms',
        name: 'network-performance/alarms',
        component: () => import(/* webpackChunkName: "alarms" */ '@/views/auth-views/NetworkPerformanceAlarmsView.vue'),
        meta: {
          title: 'Alarms'
        }
      },
      {
        path: 'network-performance/radio',
        name: 'network-performance/radio',
        component: () => import(/* webpackChunkName: "alarms" */ '@/views/auth-views/NetworkPerformanceRadioView.vue'),
        meta: {
          title: 'Radio'
        },
        children: [
          {
            path: '',
            name: 'status-overview',
            component: () => import(/* webpackChunkName: "status-overview" */ '@/views/auth-views/network-performance-radio-views/StatusOverviewView.vue')
          },
          {
            path: '5g-connectivity',
            name: '5g-connectivity',
            component: () => import(/* webpackChunkName: "5g-connectivity" */ '@/views/auth-views/network-performance-radio-views/5GConnectivityView.vue')
          },
          {
            path: '4g-connectivity',
            name: '4g-connectivity',
            component: () => import(/* webpackChunkName: "4g-connectivity" */ '@/views/auth-views/network-performance-radio-views/4GConnectivityView.vue')
          }
        ]
      },
      {
        path: 'network-performance/router',
        name: 'network-performance/router',
        component: () => import(/* webpackChunkName: 'router' */ '@/views/auth-views/NetworkPerformanceRouterView.vue'),
        meta: {
          title: 'Router'
        }
      },
      {
        path: 'network-performance/server',
        name: 'network-performance/server',
        component: () => import(/* webpackChunkName: 'server' */ '@/views/auth-views/NetworkPerformanceServerView.vue'),
        meta: {
          title: 'Server'
        }
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import(/* webpackChunkName: 'subscription' */ '@/views/auth-views/SubscriptionView.vue'),
        meta: {
          title: 'Subscription'
        }
      },
      {
        path: 'user-equipment',
        name: 'user-equipment',
        component: () => import(/* webpackChunkName: 'user-equipment' */ '@/views/auth-views/UserEquipmentView.vue'),
        meta: {
          title: 'User Equipment'
        }
      },

      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: 'user-equipment' */ '@/views/auth-views/SettingsView.vue'),
        meta: {
          title: 'Settings'
        },
        redirect: { name: 'administration' },
        children: [
          {
            path: 'administration',
            name: 'administration',
            meta: {
              'setting-title': 'Administration'
            },
            component: () => import(/* webpackChunkName: 'administration' */ '@/views/auth-views/setting-views/AdministrationView.vue')
          },
          {
            path: 'backup',
            name: 'backup',
            meta: {
              'setting-title': 'Backup'
            },
            component: () => import(/* webpackChunkName: 'backup' */ '@/views/auth-views/setting-views/BackupView.vue')
          },
          {
            path: 'configurations',
            name: 'configurations',
            meta: {
              'setting-title': 'Configurations'
            },
            component: () => import(/* webpackChunkName: 'configurations' */ '@/views/auth-views/setting-views/ConfigurationsView.vue')
          },
          {
            path: 'restore',
            name: 'restore',
            meta: {
              'setting-title': 'Restore'
            },
            component: () => import(/* webpackChunkName: 'restore' */ '@/views/auth-views/setting-views/RestoreView.vue')
          },
          {
            path: 'subscription-profile',
            name: 'subscription-profile',
            component: () => import(/* webpackChunkName: 'subscription-profile' */ '@/views/auth-views/setting-views/SubscriptionProfileView.vue'),
            meta: {
              'setting-title': 'Subscription Profile'
            },
          },
          {
            path: 'troubleshooting',
            name: 'troubleshooting',
            meta: {
              'setting-title': 'Troubleshooting'
            },
            component: () => import(/* webpackChunkName: 'troubleshooting' */ '@/views/auth-views/setting-views/TroubleshootingView.vue')
          },
          {
            path: 'alarms',
            name: 'alarms',
            meta: {
              'setting-title': 'Alarms'
            },
            component: () => import(/* webpackChunkName: 'alarms' */ '@/views/auth-views/setting-views/AlarmsView.vue')
          },
          {
            path: 'server-side-debug',
            name: 'server-side-debug',
            meta: {
              'setting-title': 'Server Side Event (SSE) Debug'
            },
            component: () => import(/* webpackChunkName: 'alarms' */ '@/views/auth-views/setting-views/ServerSideDebugView.vue')
          },
          {
            path: 'network-performance',
            name: 'network-performance',
            meta: {
              'setting-title': 'Network Performance'
            },
            component: () => import(/* webpackChunkName: 'network-performance' */ '@/views/auth-views/setting-views/NetworkPerformanceView.vue')
          }
        ]
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
